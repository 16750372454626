

import {
    Component,
    Vue,
} from "vue-property-decorator";

import AppNotificationSnackbar from "@/ui/components/AppNotificationSnackbar.vue";

@Component({
    components: {
        AppNotificationSnackbar,
    },
})
export default class AuthView extends Vue {
    private form = false;
    private email = "";
    private password = "";
    private loading = false;

    private emailRules = [
        (v: string) => !!v || "Please enter your email address",
        (v: string) => /.+@.+/.test(v) || "Please enter a valid E-Mail address",
    ];

    private passwordRules = [
        (v: string) => !!v || "Please enter your password",
    ];

    private async onLogin() {
        try {
            this.loading = true;
            await this.$store.signIn(this.email, this.password);
        } finally {
            this.loading = false;
        }
    }
}

