

import {
    Component,
    Watch,
    Vue,
} from "vue-property-decorator";

import * as utils from "@/modules/utils";

import {
    IAppSnackbarNotification,
} from "@/models/store";

const ANIMATION_DELAY = 1000;

@Component
export default class AppNotificationSnackbar extends Vue {
    private snackbar = false;

    private get current(): IAppSnackbarNotification {
        return this.$store.appSnackbarNotification || {
            type: "default",
            message: "",
        };
    }

    private onShow() {
        if(this.$store.appSnackbarNotification) {
            this.snackbar = true;
        }
    }

    private async onHide() {
        this.snackbar = false;
        await utils.timeout(ANIMATION_DELAY);
        this.$store.appSnackbarNotification = null;
    }

    private async onDisappear() {
        await utils.timeout(ANIMATION_DELAY);
        this.$store.appSnackbarNotification = null;
    }

    @Watch("$store.appSnackbarNotification")
    private onAppMessageChange() {
        this.onShow();
    }
}

