

import { DateTime } from "luxon";

import {
    Component,
    Vue,
} from "vue-property-decorator";

import { IFeedItem } from "@server/models/api";

@Component
export default class DiscoverView extends Vue {
    private get feed() {
        return this.$store._feed;
    }

    private foo = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    private isVideoUrl(url: string) {
        const videoExtensions = [
            ".avi",
            ".flv",
            ".mkv",
            ".mov",
            ".mp4",
            ".mpg",
            ".webm",
            ".wmv",
        ];

        for(const e of videoExtensions) {
            if(url.endsWith(e)) {
                return true;
            }
        }

        return false;
    }

    private formatFeedDate(item: IFeedItem) {
        return DateTime.fromISO(item.createdOn).toRelative();
    }

    private formatLikeCounter(item: IFeedItem) {
        const adjustedCounter = item.likeCounter + (item.isLiked ? 1 : 0);
        return adjustedCounter === 1
            ? "1 like"
            : `${adjustedCounter} likes`;
    }

    private onLike(item: IFeedItem) {
        item.isLiked = !item.isLiked;
    }

    private onBookmark(item: IFeedItem) {
        item.isBookmarked = !item.isBookmarked;
    }

    private onBuy() {
        this.$store.showMessageDialog({
            text: "This features is a work-in-progress! :-) Users will be able to purchase products in the app" +
                " using their Rapyd Wallet.",
            actions: ["ok"],
            defaultAction: "ok",
        });
    }
}

