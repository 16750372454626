

import {
    Component,
    Vue,
} from "vue-property-decorator";

import * as fullscreen from "@/modules/fullscreen";

import SplashView from "@/ui/views/SplashView.vue";
import AppMessageDialog from "@/ui/dialogs/AppMessageDialog.vue";
import AuthView from "@/ui/views/AuthView.vue";
import AppNotificationSnackbar from "@/ui/components/AppNotificationSnackbar.vue";

@Component({
    components: {
        AppNotificationSnackbar,
        AuthView,
        AppMessageDialog,
        SplashView,
    },
})
export default class App extends Vue {
    private get wallets() {
        return Object.entries(this.$store.profile?.walletBalances || {}).map(e => ({
            id: e[0],
            text: this.$format.money(e[0], e[1], {decimals: 2}),
        }));
    }

    private onToggleFullscreen() {
        if(fullscreen.isFullscreen()) {
            fullscreen.tryExitFullscreen();
        } else {
            fullscreen.tryEnterFullscreen();
        }
    }

    private async onSignOut() {
        await this.$store.signOut();
    }

    mounted(): void {
        this.$store.initialize();
    }
}

