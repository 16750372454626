

import {
    Component,
    Vue,
} from "vue-property-decorator";

import { AppMessageDialogAction } from "@/models/store";

@Component
export default class AppMessageDialog extends Vue {
    private get icon() {
        if(this.$store.appMessageDialog.icon) {
            return this.$store.appMessageDialog.icon;
        }

        return {
            "success": "mdi-check-circle-outline",
            "info": "mdi-information-outline",
            "warning": "mdi-alert-outline",
            "error": "mdi-alert-circle-outline",
        }[this.$store.appMessageDialog.type as string];
    }

    private get color() {
        if(this.$store.appMessageDialog.color) {
            return this.$store.appMessageDialog.color;
        }

        return this.$store.appMessageDialog.type;
    }

    private get prependActions() {
        return this.$store.appMessageDialog.actions?.filter(a => typeof a !== "string" && !!a.prepend) || [];
    }

    private get actions() {
        return this.$store.appMessageDialog.actions?.filter(a => typeof a === "string" || !a.prepend) || [];
    }

    private extractActionValue(action: AppMessageDialogAction) {
        return (typeof action === "string" ? action : action.action) as AppMessageDialogAction;
    }

    private extractActionColor(action: AppMessageDialogAction) {
        const defaultColor = this.color || "primary";

        if(this.extractActionValue(action) === this.$store.appMessageDialog.defaultAction) {
            return defaultColor;
        }

        if(typeof action !== "string") {
            return action?.color || defaultColor;
        }

        return "";
    }

    private extractActionText(action: AppMessageDialogAction) {
        return typeof action === "string" ? {
            "ok": "OK",
            "yes": "Yes",
            "no": "No",
            "cancel": "Cancel",
            "save": "Save",
            "discard": "Discard",
            "delete": "Delete",
            "reset": "Reset",
            "abort": "Abort",
        }[action] : action.text;
    }

    private onAction(action: AppMessageDialogAction) {
        if(this.$store.appMessageDialog.resolve) {
            this.$store.appMessageDialog.resolve(this.extractActionValue(action));
        }
    }
}

